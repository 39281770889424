<template>
  <CRow>
    <CCol col="12">
      <CCard>
        <CCardHeader>
          <CCol col="6" style="font-size: 25px">تعديل المنتج</CCol>
        </CCardHeader>
        <CCardBody>
          <div>
            <div class="row">
              <div class="col-md-12">
                <form @submit.prevent="UpdateProduct">
                  <CRow>
                    <CCol sm="6">
                      <CInput
                        label="الاسم"
                        id="name"
                        v-model="product.name"
                        placeholder="ادخل اسم المنتج"
                        class="required"
                        @invalid="onInvalid"
                      />
                      <div v-if(errors.name) id="validateName" class="invalid-feedback">
                        {{errors.name}}
                      </div>
                    </CCol>
                    <CCol sm="6">
                      <CInput
                        label="الاسم باللغة العربية"
                        id="name_ar"
                        v-model="product.ar_name"
                        placeholder="ادخل اسم المنتج"
                        class="required"
                        @invalid="onInvalid"
                      />
                    </CCol>
                    <CCol sm="6">
                      <div class="form-group">
                        <label for="provinces">
                          الماركات
                          <span class="star">*</span>
                        </label>
                        <multiselect
                          v-model="brand"
                          :allow-empty="false"
                          :value="brand"
                          @select="onProvincesChange($event)"
                          id="brands"
                          :options="brands"
                          :searchable="true"
                          selectedLabel=" العنصر المحدد"
                          deselectLabel="اضغط لإلغاء اختيار هذا العنصر"
                          selectLabel="اضغط لإختيار هذا العنصر"
                          :close-on-select="true"
                          class="required"
                          placeholder="اختر    "
                          label="name"
                          track-by="name"
                          required
                        >
                          <span slot="noOptions">لا توجد محافظات</span>
                          <span slot="noResult">لا توجد نتيجة</span>
                        </multiselect>
                        <div v-if(errors.brand_id) id="validateBrandId" class="invalid-feedback">
                          {{errors.brand_id}}
                        </div>
                      </div>
                    </CCol>
                    <CCol sm="6">
                      <div class="form-group">
                        <label for="categories">
                          الفئات الرئيسية
                          <span class="star">*</span>
                        </label>
                        <multiselect
                          class="required"
                          v-model="selected_category"
                          :allow-empty="false"
                          :value="selected_category"
                          @select="onCategoriesChange($event)"
                          id="categories"
                          :options="categories"
                          :searchable="true"
                          selectedLabel=" العنصر المحدد"
                          deselectLabel="اضغط لإلغاء اختيار هذا العنصر"
                          selectLabel="اضغط لإختيار هذا العنصر"
                          :close-on-select="true"
                          placeholder="اختر اولا   "
                          label="name"
                          track-by="name"
                          required
                        >
                          <span slot="noOptions">اللائحة فارغة</span>
                          <span slot="noResult">لا توجد نتيجة</span>
                        </multiselect>
                      </div>
                    </CCol>
                    <CCol sm="6">
                      <div class="form-group">
                        <label for="subCategories">
                          الفئات الفرعية
                          <span class="star">*</span>
                        </label>
                        <multiselect
                          v-model="selected"
                          :value="selected"
                          :allow-empty="false"
                          id="subCategories"
                          :options="subCategories"
                          :searchable="true"
                          selectedLabel=" العنصر المحدد"
                          deselectLabel="اضغط لإلغاء اختيار هذا العنصر"
                          selectLabel="اضغط لإختيار هذا العنصر"
                          :close-on-select="true"
                          class="required"
                          placeholder="اختر ثانيا   "
                          label="name"
                          track-by="name"
                          required
                        >
                          <span slot="noOptions">اللائحة فارغة</span>
                          <span slot="noResult">لا توجد نتيجة</span>
                        </multiselect>
                        <div v-if(errors.sub_category_id) id="validateSubCategoryId" class="invalid-feedback">
                          {{errors.sub_category_id}}
                        </div>
                      </div>
                    </CCol>
                  </CRow>

                  <CRow>
                    <CCol sm="6">
                      <label id="mobile">
                        السعر
                        <span class="star">*</span>
                      </label>
                      <input
                        type="number"
                        v-model="product.price"
                        class="form-control"
                        placeholder="أدخل السعر "
                        value=""
                      />
                      <div v-if(errors.price) id="validatePrice" class="invalid-feedback">
                        {{errors.price}}
                      </div>
                    </CCol>
                    <CCol sm="6">
                      <CInput
                        type="number"
                        label="التخفيض"
                        id="discount"
                        v-model="product.discount"
                        placeholder="أدخل نسبة التخفيض"
                      />
                    </CCol>
                  </CRow>

                  <br />
                  <CRow>
                    <CCol sm="12">
                      <CTextarea
                        label="الوصف (اختياري)"
                        placeholder="المحتوي   "
                        horizontal
                        rows="9"
                        v-model="product.description"
                      />
                      <div v-if(errors.description) id="validateDescription" class="invalid-feedback">
                        {{errors.description}}
                      </div>
                    </CCol>
                  </CRow>
                  <CRow>
                <CCol sm="12">
                  <CTextarea
                    label="الوصف باللغة العربية"
                    placeholder="المحتوي  "
                    horizontal
                    rows="9"
                    v-model="product.ar_description"
                  />
                </CCol>
              </CRow>
                  <CRow>
                    <CCol sm="12">
                      <CRow>
                        <CCol
                          sm="3"
                          v-for="(url, index) in JSON.parse(product.images)"
                          :key="url"
                        >
                          <div class="form-group">
                            <label style="padding: 5px"
                              >الصورة {{ index + 1 }}</label
                            >
                            <!-- style="height: 100px;width: 100px;" -->
                            <img
                              fluid
                              v-bind:src="url"
                              class="img-rounded"
                              style="height: 100px; width: 100px"
                            />
                          </div>
                          <div class="form-group">
                            <label style="padding: 5px"> صورة جديدة</label>
                            <input
                              type="file"
                              :id="'image_' + (index + 1)"
                              v-on:change="
                                onFileChange($event, 'image_' + (index + 1))
                              "
                            />
                            <small class="form-text text-muted">حجم الصورة يجب ان لايتجاوز 5 ميقا</small>
                            <div v-if(errors.image) class="invalid-feedback">
                              {{errors.image}}
                            </div>
                          </div>
                        </CCol>
                        <CCol sm="3" v-if="product.type != 2">
                          <div
                            v-if="
                              product.images &&
                              parseInt(JSON.parse(product.images).length) < 4
                            "
                          >
                            <label style="padding: 5px">إضافة المزيد</label>

                            <div class="role_3 img-rounded">
                              <div
                                id="my-strictly-unique-vue-upload-multiple-image "
                                fluid
                                style="display: flex; justify-content: center"
                              >
                                <vue-upload-multiple-image
                                  @upload-success="uploadImageSuccess"
                                  @before-remove="beforeRemove"
                                  @edit-image="editImage"
                                  :data-images="images"
                                  idUpload="myIdUpload"
                                  editUpload="myIdEdit"
                                  dragText="اضغط لتحميل الصورة"
                                  browseText="تصفح الصور"
                                  primaryText=""
                                  popupText=""
                                  :maxImage="
                                    4 -
                                    parseInt(JSON.parse(product.images).length)
                                  "
                                ></vue-upload-multiple-image>
                              </div>
                            </div>
                          </div>
                        </CCol>
                      </CRow>
                    </CCol>
                  </CRow>

                  <br />
                  <button type="submit" class="btn btn-success">
                    تحديث البيانات
                  </button>
                  &emsp;
                  <CButton color="warning" @click="goBack">رجوع</CButton>
                </form>
              </div>
            </div>
          </div>
        </CCardBody>
      </CCard>
    </CCol>

  </CRow>
</template>

<script>
import "vue-select/dist/vue-select.css";
import swal from "sweetalert";
import $ from "jquery";
import VueUploadMultipleImage from "vue-upload-multiple-image";
export default {
  name: "EditProduct",
  data() {
    return {
      product: [],
      image_1: "",
      image_2: "",
      image_3: "",
      image_4: "",
      image_5: "",
      categories: [],
      subCategories: [],
      selected: "",
      selected_brand: "",
      selected_category: "",
      description: [],
      brands: [],
      images: [],
      errors: [],
    };
  },
  components: {
    VueUploadMultipleImage,
  },
  created() {
    let http = this.$http;
    this.$http
      .get(`${process.env.VUE_APP_URL}products/${this.$route.params.id}`)
      .then((response) => {
        this.product = response.data.data;
        console.log(this.product)
        this.selected = this.product.sub_category;
        this.selected_category = this.product.category;
        this.brand = this.product.brand;
        let brands = [];
        http.get(`${process.env.VUE_APP_URL}brands`).then((response) => {
          console.log(response);
          $.each(response.data.data, function (key, value) {
            brands.push({
              id: value.id,
              name: value.name,
            });
          });
        });

        let categories = [];
        this.$http
          .get(`${process.env.VUE_APP_URL}categories`)
          .then((response) => {
            console.log(response);
            $.each(response.data.data, function (key, value) {
              categories.push({
                id: value.id,
                name: value.name,
              });
            });
            this.categories = categories;
          });

        let subCategories = [];
        this.$http
          .get(`${process.env.VUE_APP_URL}sub-categories`)
          .then((response) => {
            $.each(response.data.data, function (key, value) {
              subCategories.push({
                id: value.id,
                name: value.name,
              });
            });
            this.subCategories = subCategories;
          });
      });
  },
  methods: {
    onCategoriesChange($event) {
      this.selected_category = $event.id;
      console.log($event.id);
      let subCategories = [];
      this.$http
        .get(
          `${process.env.VUE_APP_URL}categories/${this.selected_category}/sub-categories`
        )
        .then((response) => {
          console.log(response);
          $.each(response.data.data, function (key, value) {
            subCategories.push({
              id: value.id,
              name: value.name,
            });
          });
          this.subCategories = subCategories;
        });
    },
    onFileChange(e, name) {
      console.log(this.product, name);
      this.product[name] = e.target.files[0];
      this[name] = e.target.files[0];
    },
    UpdateProduct(e) {
      this.errors = [];
      if (!this.product.name) this.errors.push(`الاسم مطلوب`);
      if (!this.product.price) this.errors.push(`السعر مطلوب`);
      if (!this.brand) this.errors.push(`الماركة مطلوبة`);
      if (!this.selected_category) this.errors.push(`الفئة الرئيسية مطلوبة`);
      if (!this.selected) this.errors.push(`الفئة الفرعية مطلوبة`);

      if (this.errors.length) {
        swal({
          title: this.errors[0],
          buttons: "تم",
        });
        e.preventDefault();
        return false;
      }
      let currentObj = this;
      let formData = new FormData();
      formData.append("_method", "PUT");

      formData.append("name", this.product.name);
      formData.append("name_ar", this.product.ar_name);
      formData.append("price", this.product.price);
      if (this.product.discount) formData.append("discount", this.product.discount);

      if (this.image_1) {
              if (this.image_1.size > 5000000) {
                e.preventDefault();
                this.errors.push('image')
                this.errors.image = 'حجم الصورة تجاوز 5 ميقا'
                swal({ title: 'هناك خطأ في ادخال البيانات في حقل او اكثر', buttons: "تم" });
                return 0;
              } else {
                formData.append("image_1", this.image_1);
              }
            }
            if (this.image_2) {
              if (this.image_1.size > 5000000) {
                e.preventDefault();
                this.errors.push('image')
                this.errors.image = 'حجم الصورة تجاوز 5 ميقا'
                swal({ title: 'هناك خطأ في ادخال البيانات في حقل او اكثر', buttons: "تم" });
                return 0;
              } else {
                formData.append("image_2", this.image_2);
              }
            }
            if (this.image_3) {
              if (this.image_1.size > 5000000) {
                e.preventDefault();
                this.errors.push('image')
                this.errors.image = 'حجم الصورة تجاوز 5 ميقا'
                swal({ title: 'هناك خطأ في ادخال البيانات في حقل او اكثر', buttons: "تم" });
                return 0;
              } else {
                formData.append("image_3", this.image_3);
              }
            }
            if (this.image_4) {
              if (this.image_1.size > 5000000) {
                e.preventDefault();
                this.errors.push('image')
                this.errors.image = 'حجم الصورة تجاوز 5 ميقا'
                swal({ title: 'هناك خطأ في ادخال البيانات في حقل او اكثر', buttons: "تم" });
                return 0;
              } else {
                formData.append("image_4", this.image_4);
              }
            }
            if (this.image_5) {
              if (this.image_1.size > 5000000) {
                e.preventDefault();
                this.errors.push('image')
                this.errors.image = 'حجم الصورة تجاوز 5 ميقا'
                swal({ title: 'هناك خطأ في ادخال البيانات في حقل او اكثر', buttons: "تم" });
                return 0;
              } else {
                formData.append("image_5", this.image_5);
              }
            }

      if (this.selected.id)
        formData.append("sub_category_id", this.selected.id);
      if (this.selected_category.id)
        formData.append("category_id", this.selected_category.id);
      if (this.brand.id) formData.append("brand_id", this.brand.id);
      if (this.product.description)
        formData.append("description", this.product.description);
      if (this.product.ar_description)
        formData.append("description_ar", this.product.ar_description);

      this.$http
        .post(
          `${process.env.VUE_APP_URL}products/${this.$route.params.id}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((response) => {
          if (!response.data.error) {
            swal({
              title: response.data.message,
              buttons: "تم",
            });
            currentObj.$router.push({ path: "/products" });
          } else {
            currentObj.errors = response.data.data;
          }
        });
    },
    onInvalid() {
      var element = $(this).closest(".ui-tabs-panel").index();
      //goto tab id
      $("#tabs").tabs().tabs("option", "active", element);
    },
    onChange(branch, id) {
      this.menu_branches[id] = branch;
    },
    uploadImageSuccess(formData, index, fileList) {
      let image_index = index + JSON.parse(this.product.images).length + 1;
      var file = dataURLtoFile(fileList[index].path, "image_" + image_index);
      this.product["image_" + image_index] = file;
      this["image_" + image_index] = file;
      console.log(this["image_" + image_index])
    },
    beforeRemove(index, done, fileList) {
      console.log("index", index, fileList);
      var r = confirm("remove image");
      if (r == true) {
        done();
      }
    },
    editImage(formData, index, fileList) {
      console.log("edit data", formData, index, fileList);
    },
    goBack() {
      this.productsOpened
        ? this.$router.go(-1)
        : this.$router.push({
            path: "/products",
          });
    },
    checkForm: function (e) {
      if (this.image) {
        return true;
      }

      this.errors = [];

      if (!this.image) {
        swal({
          title: "الصورة مطلوبة",
          buttons: "تم",
        });
      }

      e.preventDefault();
    },
  },
};

function dataURLtoFile(dataurl, filename) {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, {
    type: mime,
  });
}
</script>

<style>
#my-strictly-unique-vue-upload-multiple-image {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
  width: 100px !important;
}

h1,
h2 {
  font-weight: normal;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}
</style>
